.App {
  width: 100%;
  height: 98%;
  background-color: #000;
}

body {
  background: #000;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to bottom right, rgb(15, 4, 121), rgb(9, 0, 128)); */
}
main {
  background: rgb(46, 43, 43);
  width: 100%;
  height: 98%;
  /* padding: 0 1rem 2rem; */
  border-radius: 10px;
}

.page {
  width: 100%;
}
.page .container {
  display: grid;
  /* grid-template-columns: auto 425px; */
  /* gap: 1rem; */
  align-items:start;
  width: 100%;
}


.bodyRegister{
  width: 100%;
  position: fixed;
  text-align: center;
  right: 0;
  z-index: 9999;
  top: 300;
 
}
.bodyRegister img{
  position: absolute; /* ตั้งค่าให้เป็น absolute เพื่อจัดตำแหน่งภายใน .bodyfloating */
  right: 0; /* ชิดขอบขวาของ .bodyfloating */
  margin-top: 25vh; /* หรือปรับแต่งตำแหน่งตั้งแต่ด้านบนตามต้องการ */

  width:15vw;
  height: 15vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bodyRegister img:hover{
  transform: scale(1.05); /* ขยายขนาดเล็กน้อย */
 /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); *//* เพิ่มเงา */
}

/* การเพิ่มความสว่างเมื่อ hover (ถ้าต้องการเพิ่ม) */

.floatingDiv:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 /* background: rgba(255, 255, 255, 0.2);  สีขาวที่มีความโปร่งใส */
  border-radius: 5px; /* เพิ่มเส้นขอบมุมโค้ง */
}


.do-container{
  container-type: inline-size;
  width: 90%;
  margin:auto;
  background: rgb(46, 43, 43);
  background: rgb(0, 0, 0);
  margin-top: 31.5%;
}

.do-container-query{
  container-type: inline-size;
  margin:auto;
  width:100%;
  background: rgb(0, 0, 0);
  background: rgb(46, 43, 43);

}

.card-wrap{
  display:grid;
  grid-template-columns:repeat(1,1fr);
  z-index: 2; /* ป้องกันการซ้อนทับ */
  position: relative; /* ป้องกันไม่ให้ซ้อนทับกับองค์ประกอบอื่นๆ */
}
.card-img{
  width:100%;
}

.card-wrap-Logopng{
  position: absolute; /* ทำให้โลโก้อยู่เหนือภาพหลัก */

  width: 100%; /* ขนาดของโลโก้ */
  height: auto; /* รักษาสัดส่วนของโลโก้ */
  z-index: 100; /* ป้องกันการซ้อนทับ */
  position: relative; /* ป้องกันไม่ให้ซ้อนทับกับองค์ประกอบอื่นๆ */
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
margin-top: -10%;

}
.card-img-Logopng{
  width: calc(20% - 10px);  
  object-fit: contain; /* ปรับขนาดของโลโก้ให้พอดีกับพื้นที่ */
}


.do-container-button{
  container-type: inline-size;
  margin: auto;
  /* margin-top: -15%; */
  position: fixed; /* ยึดตำแหน่งให้คงที่ */
  height: auto; /* ปรับขนาด height ตามความต้องการ */
  width: 100%;
  /* background-color: aqua; */
  display: flex; /* ใช้ Flexbox เพื่อจัดแนวเนื้อหาภายใน */
  justify-content: flex-end; /* จัดกลางในแนวนอน */
  align-items: center; /* จัดกลางในแนวตั้ง */
  z-index: 9999;
  top:0%;
  margin-top: 1%;
  }

  .card_button {
   display: grid;
   grid-template-columns: repeat(3, 1fr); /* สร้าง 2 คอลัมน์ */
   grid-template-rows: repeat(1, 1fr); 

   gap:1vw;
   width: 30%; 
   height: 100%; 
   box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
   overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
   /* background-color: #d92626; */
   /* margin-top: -5%; */
  }

  .card_button img{
    width: 100%;
    height: 100%; /* ขยายภาพให้เต็มแถว */
   
    display: block; /* ป้องกันช่องว่างด้านล่างของภาพ */
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
  }

  

.card_button img:hover {
  cursor: pointer;
  mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0);
  animation: glow 2s linear infinite;
  transform: scale(1.01);
}



.card_button:hover img {
 /* filter: brightness(1.2);*/ /* เพิ่มความสว่างของภาพเมื่อ hover */
}

.card_button:hover::after {
  /*border-color: rgba(255, 255, 255, 0.8);*/ /* เพิ่มความชัดของแสงวิ่งเมื่อ hover */
}



.card-wrap-logo{
  position: absolute; /* ทำให้โลโก้อยู่เหนือภาพหลัก */

  width: 100%; /* ขนาดของโลโก้ */
  height: auto; /* รักษาสัดส่วนของโลโก้ */
  margin-top: -7%;
}
.card-img-logo{
  width: 100%; /* ให้โลโก้เต็มพื้นที่ของ .card-wrap-logo */
  height: 100%; /* ให้โลโก้เต็มพื้นที่ที่กำหนด */
  object-fit: contain; /* ปรับขนาดของโลโก้ให้พอดีกับพื้นที่ */
}



.bodyJackport {
  container-type: inline-size;
  margin: auto;
  position: fixed; /* ยึดตำแหน่งให้คงที่ */
  height: auto; /* ปรับขนาด height ตามความต้องการ */
  width: 100%;
  /* margin-top: 31%; */
  display: flex; /* ใช้ Flexbox เพื่อจัดแนวเนื้อหาภายใน */
  justify-content: center; /* จัดกลางในแนวนอน */
  align-items: center; /* จัดกลางในแนวตั้ง */
  z-index: 9999;

}

.cardJackport {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* สร้าง 2 คอลัมน์ */
  grid-template-rows: repeat(1, 1fr); 
  gap: 5px; /* ระยะห่างระหว่างภาพ */
  width: 70%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  height: 80%; /* ใช้ความสูงทั้งหมดของ .do-container-query-6game */
  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
}


.cardJackport img{
  width: 100%; /* ขยายภาพให้เต็มคอลัมน์ */
  height: 100%; /* ขยายภาพให้เต็มแถว */
  object-fit: cover; /* ทำให้ภาพเต็มช่องโดยไม่เสียสัดส่วน */
  display: block; /* ป้องกันช่องว่างด้านล่างของภาพ */
 
}


.bodyRunNumber
{

  container-type: inline-size;
  margin: auto;
  margin-top: 1%;
  position: fixed; /* ยึดตำแหน่งให้คงที่ */
  height: auto; /* ปรับขนาด height ตามความต้องการ */
  width: 100%;
  /* background-color: rgb(0, 255, 0); */
  display: flex; /* ใช้ Flexbox เพื่อจัดแนวเนื้อหาภายใน */
  justify-content: center; /* จัดกลางในแนวนอน */
  align-items: center; /* จัดกลางในแนวตั้ง */
  z-index: 9999;
}

.bodyRunNumber p {
  background-color: #ce5d5d;
  width: 50%;
  font-size: 4.5vw; /* ปรับขนาดฟอนต์ตามความกว้างของหน้าจอ */
  margin: 0; /* ลบ margin ที่อาจมีอยู่ */
  padding-left: 10vw; 
  animation: blink 0.5s infinite; /* หรือความเร็วอื่นที่ต้องการ */
  background: linear-gradient(45deg, #FFD700, #FFCC00, #FFD700); /* สีทองสว่าง */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* ทำให้ข้อความโปร่งใส */
  color: transparent; /* ป้องกันไม่ให้เห็นข้อความสีปกติ */
  text-align: right; /* จัดข้อความให้อยู่ชิดขวา */
  text-shadow: 
    0 0 10px #FFD700, /* เงาสีทองขนาดเล็ก */
    0 0 20px #FFD700, /* เงาสีทองขนาดกลาง */
    0 0 30px #FFD700, /* เงาสีทองขนาดใหญ่ */
    0 0 40px #FF8C00, /* เพิ่มเงาสีส้มเข้ม */
    0 0 70px #FF8C00, /* เพิ่มเงาสีส้มเข้ม */
    0 0 80px #FF8C00; /* เพิ่มเงาสีส้มเข้มขนาดใหญ่ */
  font-family: 'Great Vibes', cursive
}



.bodystep3{
  container-type: inline-size;
  margin:auto;
  margin-top: 8%;

}

.cardstep3 {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* สร้าง 2 คอลัมน์ */
  grid-template-rows: repeat(1, 1fr); 
  gap: 5px; /* ระยะห่างระหว่างภาพ */
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  height: 100%; /* ใช้ความสูงทั้งหมดของ .do-container-query-6game */
  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
}


.cardstep3 img{
  width: 100%; /* ขยายภาพให้เต็มคอลัมน์ */
  height: 100%; /* ขยายภาพให้เต็มแถว */
  object-fit: cover; /* ทำให้ภาพเต็มช่องโดยไม่เสียสัดส่วน */
  display: block; /* ป้องกันช่องว่างด้านล่างของภาพ */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* เพิ่ม Transition สำหรับการเปลี่ยนแปลง */

}
.cardstep3 img:hover {
  animation: shake 0.5s ease-in-out; /* เรียกใช้งาน Animation เมื่อเมาส์ชี้ */
  cursor: pointer;
  opacity: 0.8;

}


.bodycasino{
  container-type: inline-size;
  margin:auto;
  /* margin-top: 40%; */
/* background-color: aqua; */
}

.cardcasino {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* สร้าง 2 คอลัมน์ */
  grid-template-rows: repeat(1, 1fr); 
  gap: 5px; /* ระยะห่างระหว่างภาพ */
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  height: 100%; /* ใช้ความสูงทั้งหมดของ .do-container-query-6game */
  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
  display: flex;
  flex-direction: column;
  align-items: center;
}


.cardcasino img{
  width: 20%; /* ขยายภาพให้เต็มคอลัมน์ */
  height: 90%; /* ขยายภาพให้เต็มแถว */
  object-fit: cover; /* ทำให้ภาพเต็มช่องโดยไม่เสียสัดส่วน */
  display: block; /* ป้องกันช่องว่างด้านล่างของภาพ */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* เพิ่ม Transition สำหรับการเปลี่ยนแปลง */

  
}
.cardcasino:hover {
  transform: scale(1.1); /* ขยายกล่องเมื่อเมาส์ชี้ */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* เพิ่มเงาให้กล่องเพื่อทำให้มันเด่นขึ้น */
  cursor: pointer; /* เปลี่ยนเคอร์เซอร์ให้เป็นแบบชี้ */
  animation: none; /* หยุด Animation วิบวับเมื่อเมาส์ชี้ */
}

.cardcasino:active {
  animation: float 1s ease-in-out; /* เรียกใช้งาน Animation ลอยเมื่อคลิก */
}



.bodyDetailcasino{

  container-type: inline-size;
  margin: auto;
  /* margin-top: -15%; */

  height: auto; /* ปรับขนาด height ตามความต้องการ */
  width: 90%;
  background-color: rgb(46, 43, 43);
  display: flex; /* ใช้ Flexbox เพื่อจัดแนวเนื้อหาภายใน */
  justify-content: center; /* จัดกลางในแนวนอน */
  align-items: center; /* จัดกลางในแนวตั้ง */
 border-radius: 20px;
 margin-top: 2%;
}

.cardDetailcasino {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* สร้าง 2 คอลัมน์ */
  grid-template-rows: repeat(1, 1fr); 
  margin: 2%;

  gap: 3vw; /* ระยะห่างระหว่างภาพ */
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  /* height: 80%;  */
  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
}
.cardDetailcasino img {
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  height: 98%;
  text-align: center;
  aspect-ratio: 1; 
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; 
  animation: pulse2 1.5s infinite;
}




.bodysports{
  container-type: inline-size;
  margin:auto;
  margin-top: 2%;
  /* margin-top: 40%; */
/* background-color: aqua; */
}

.cardsports {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* สร้าง 2 คอลัมน์ */
  grid-template-rows: repeat(1, 1fr); 
  gap: 5px; /* ระยะห่างระหว่างภาพ */
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  height: 100%; /* ใช้ความสูงทั้งหมดของ .do-container-query-6game */
  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
  display: flex;
  flex-direction: column;
  align-items: center;
}


.cardsports img{
  width: 20%; /* ขยายภาพให้เต็มคอลัมน์ */
  height: 90%; /* ขยายภาพให้เต็มแถว */
  object-fit: cover; /* ทำให้ภาพเต็มช่องโดยไม่เสียสัดส่วน */
  display: block; /* ป้องกันช่องว่างด้านล่างของภาพ */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* เพิ่ม Transition สำหรับการเปลี่ยนแปลง */

  
}
.cardsports:hover {
  transform: scale(1.1); /* ขยายกล่องเมื่อเมาส์ชี้ */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* เพิ่มเงาให้กล่องเพื่อทำให้มันเด่นขึ้น */
  cursor: pointer; /* เปลี่ยนเคอร์เซอร์ให้เป็นแบบชี้ */
  animation: none; /* หยุด Animation วิบวับเมื่อเมาส์ชี้ */
}

.cardsports:active {
  animation: float 1s ease-in-out; /* เรียกใช้งาน Animation ลอยเมื่อคลิก */
}



.bodyDetailsports{
  container-type: inline-size;
  margin: auto;
  /* margin-top: -15%; */

  height: auto; /* ปรับขนาด height ตามความต้องการ */
  width: 90%;
  background-color: rgb(46, 43, 43);
  display: flex; /* ใช้ Flexbox เพื่อจัดแนวเนื้อหาภายใน */
  justify-content: center; /* จัดกลางในแนวนอน */
  align-items: center; /* จัดกลางในแนวตั้ง */
  border-radius: 20px;
  margin-top: 2%;
}

.cardDetailsports {
  display: grid;
  grid-template-columns:42% 58%; /* กำหนดขนาดคอลัมน์ */
  /* grid-template-rows: repeat(1, 1fr);  */
  margin: 2%;

  gap: 3vw; /* ระยะห่างระหว่างภาพ */
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  height: auto; 
  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
}


.bodyDetailsportsbox1 {
  margin: 2%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.bodyDetailsportsbox1 img{
  width: 25vw ;
  height: 20vw ;

}


.bodyDetailsportsbox2 {

  margin: 2%;
  width: 93%;
}

.bodyDetailsportsbox2 p {
  color: #fff;
  flex: 1; /* ให้ <p> ขยายเต็มพื้นที่ที่มีอยู่ */
  line-height: 1.5; /* เพิ่มความสูงของบรรทัดเพื่อให้อ่านง่าย */
  overflow: auto; /* เพิ่ม scroll bar ถ้าข้อความเกินขนาดของ <p> */
  font-size: 1.2vw;
  text-indent: 1.5em;
  padding: 20px; /* เพิ่ม padding รอบ ๆ ข้อความภายใน <p> */
}




.bodygame{
  container-type: inline-size;
  margin:auto;
  margin-top: 2%;
  /* margin-top: 40%; */
/* background-color: aqua; */
}

.cardgame {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* สร้าง 2 คอลัมน์ */
  grid-template-rows: repeat(1, 1fr); 
  gap: 5px; /* ระยะห่างระหว่างภาพ */
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  height: 100%; /* ใช้ความสูงทั้งหมดของ .do-container-query-6game */
  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
  display: flex;
  flex-direction: column;
  align-items: center;
}


.cardgame img{
  width: 20%; /* ขยายภาพให้เต็มคอลัมน์ */
  height: 90%; /* ขยายภาพให้เต็มแถว */
  object-fit: cover; /* ทำให้ภาพเต็มช่องโดยไม่เสียสัดส่วน */
  display: block; /* ป้องกันช่องว่างด้านล่างของภาพ */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* เพิ่ม Transition สำหรับการเปลี่ยนแปลง */

  
}
.cardgame:hover {
  transform: scale(1.1); /* ขยายกล่องเมื่อเมาส์ชี้ */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* เพิ่มเงาให้กล่องเพื่อทำให้มันเด่นขึ้น */
  cursor: pointer; /* เปลี่ยนเคอร์เซอร์ให้เป็นแบบชี้ */
  animation: none; /* หยุด Animation วิบวับเมื่อเมาส์ชี้ */
}

.cardgame:active {
  animation: float 1s ease-in-out; /* เรียกใช้งาน Animation ลอยเมื่อคลิก */
}



.bodygameDetail{

  container-type: inline-size;
  margin: auto;
  /* margin-top: -15%; */

  height: auto; /* ปรับขนาด height ตามความต้องการ */
  width: 90%;
  background-color: rgb(46, 43, 43);
  display: flex; /* ใช้ Flexbox เพื่อจัดแนวเนื้อหาภายใน */
  justify-content: center; /* จัดกลางในแนวนอน */
  align-items: center; /* จัดกลางในแนวตั้ง */
 border-radius: 20px;
 margin-top: 2%;
}

.cardgameDetail {
  display: grid;
  grid-template-columns: repeat(9, 1fr); /* สร้าง 2 คอลัมน์ */
  grid-template-rows: repeat(2, 1fr); 
  margin: 2%;

  gap: 3vw; /* ระยะห่างระหว่างภาพ */
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  /* height: 80%;  */
  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
}
.cardgameDetail img {
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  height: 98%;
  text-align: center;
  aspect-ratio: 1; 
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; 
  animation: pulse2 1.5s infinite;
}

.cardgameDetail img:hover {
  transform: scale(1.1); /* ขยายกล่องเมื่อเมาส์ชี้ */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* เพิ่มเงาให้กล่องเพื่อทำให้มันเด่นขึ้น */
  cursor: pointer; /* เปลี่ยนเคอร์เซอร์ให้เป็นแบบชี้ */
  animation: none; /* หยุด Animation วิบวับเมื่อเมาส์ชี้ */
}




.bodyguarantee{

  container-type: inline-size;
  margin: auto;
  /* margin-top: -15%; */

  height: auto; /* ปรับขนาด height ตามความต้องการ */
  width: 86%;
  background-color: rgb(46, 43, 43);
  display: flex; /* ใช้ Flexbox เพื่อจัดแนวเนื้อหาภายใน */
  justify-content: center; /* จัดกลางในแนวนอน */
  align-items: center; /* จัดกลางในแนวตั้ง */
 border-radius: 20px;
 margin-top: 2%;
}

.cardguarantee {
  display: flex;
  justify-content: center; /* จัดกลางในแนวนอน */
  align-items: center; /* จัดกลางในแนวตั้ง */
  margin: 2%;
  width: 98%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */

  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */

  
}
.cardguarantee img {
  width: 100vw ;
  height: 35vw ;
  text-align: center;
  aspect-ratio: 1; 
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; 

}

.cardguarantee img:hover {
  cursor: pointer;
  animation: bounce 3s ease-in-out;
}




.bodyTestgame{

  container-type: inline-size;
  margin: auto;
  /* margin-top: -15%; */

  height: auto; /* ปรับขนาด height ตามความต้องการ */
  width: 90%;
  background-color: rgb(46, 43, 43);
  display: flex; /* ใช้ Flexbox เพื่อจัดแนวเนื้อหาภายใน */
  justify-content: center; /* จัดกลางในแนวนอน */
  align-items: center; /* จัดกลางในแนวตั้ง */
 border-radius: 20px;
 margin-top: 2%;
 margin-bottom: 5%;
}

.cardTestgame {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* สร้าง 2 คอลัมน์ */
  grid-template-rows: repeat(2, 1fr); 
  margin: 2%;

  gap: 3vw; /* ระยะห่างระหว่างภาพ */
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  height: auto; 
  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
}
.cardTestgame img {
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  height: 98%;
  text-align: center;
  aspect-ratio: 1; 
  transition: transform 1s ease-in-out; /* เพิ่ม transition สำหรับเอฟเฟกต์การหมุน */
  transform-style: preserve-3d; /* ทำให้การหมุนดูสมจริงใน 3D */
}

.cardTestgame img:hover {
  cursor: pointer;
  transform: rotateY(360deg); /* หมุน 360 องศาในแนวนอนเมื่อ hover */

}



.bodyGamegroup{

  container-type: inline-size;
  margin: auto;
  height: auto; 
  width: 96%;
  display: flex; 
 margin-top: 1%;
 
}

.cardGamegroup{
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* สร้าง 2 คอลัมน์ */
  grid-template-rows: repeat(1, 1fr); 
  margin: 2%;

  gap: 3vw; /* ระยะห่างระหว่างภาพ */
  width: 100%; 
  /* height: 80%;  */
  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
}
.cardGamegroup img {
  flex: 0 1 auto; /* ไม่ขยายเกินขนาดที่กำหนด */
  width: calc(99% - 10px);  
 
}




/* สไตล์สำหรับ popupPromotion */
.popupPromotion {
  position: fixed;
  top: 6px;
  width: 30%;
  height: 96vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  animation: fadeIn 0.5s ease-out; /* แอนิเมชันการปรากฏ */
  cursor: pointer; /* เปลี่ยนเคอร์เซอร์เมื่อวางเมาส์บน popup */

}

/* สไตล์สำหรับเนื้อหาของ popup */
.popupPromotion-content {
  /* ยังไม่ได้ใช้ */
}

.bodypopupPromotion {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 100%;
  position: fixed;
  top: 0;
  text-align: center;
  right: 0;
  /* bottom:  0; */
  gap: 10em;
  z-index: 1000001; /* ปรับ z-index ให้อยู่ด้านบน popupPromotion */
  /* background-color: #61dafb; */
  padding-top: 5em;
  padding-bottom: 5em;
}

.bodypopupPromotion_background1,
.bodypopupPromotion_background2,
.bodypopupPromotion_background3 {
  flex: 1; /* แต่ละส่วนจะได้รับพื้นที่เท่ากัน */
  width: calc(100%); /* ยืดเต็มความกว้าง */
  margin: 10px 0; /* ระยะห่างระหว่างกล่องแนวตั้ง */
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center; 
  background-attachment: scroll;
  background-size: contain; 
  animation: pulse2 1.5s infinite; /* เอฟเฟกต์กระพริบแบบย่อขยาย */
  position: relative;
}

.bodypopupPromotion_background1 {
  background-image: url(../src/promotion/addFriend.jpg);
}

.bodypopupPromotion_background2 {
  background-image: url(../src/promotion/newmember.jpg);
}

.bodypopupPromotion_background3 {
  background-image: url(../src/promotion/birthday.jpg);
}


.bodypopupPromotion_background1 a {
  position: static; /* ตั้งค่า position เป็น absolute เพื่อกำหนดตำแหน่งที่แน่นอน */

/* 
  height: 20px;
  width: 80px; */
  height:2%;
  width: 20%;
  color: white; /* เพิ่มสีข้อความให้ดูเด่น */
  border: none; /* ลบขอบ */
  border-radius: 5px; /* เพิ่มความโค้งให้มุม */
  cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็น pointer เมื่อวางเมาส์ */
  display: flex; /* ใช้ flex เพื่อจัดไอคอนและข้อความในแนวนอน */
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-left: 70.5%;


}


.bodypopupPromotion_background2 a {
  position: static; /* ตั้งค่า position เป็น absolute เพื่อกำหนดตำแหน่งที่แน่นอน */

  /* 
    height: 20px;
    width: 80px; */
    height:2%;
    width: 20%;

    color: white; /* เพิ่มสีข้อความให้ดูเด่น */
    border: none; /* ลบขอบ */
    border-radius: 5px; /* เพิ่มความโค้งให้มุม */
    cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็น pointer เมื่อวางเมาส์ */
    display: flex; /* ใช้ flex เพื่อจัดไอคอนและข้อความในแนวนอน */
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-left: 70.5%;

}

.bodypopupPromotion_background3 a {
  position: static; /* ตั้งค่า position เป็น absolute เพื่อกำหนดตำแหน่งที่แน่นอน */

  /* 
    height: 20px;
    width: 80px; */
    height:2%;
    width: 20%;
    color: white; /* เพิ่มสีข้อความให้ดูเด่น */
    border: none; /* ลบขอบ */
    border-radius: 5px; /* เพิ่มความโค้งให้มุม */
    cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็น pointer เมื่อวางเมาส์ */
    display: flex; /* ใช้ flex เพื่อจัดไอคอนและข้อความในแนวนอน */
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-left: 70.5%;

}

.animated-button1 {
  background: linear-gradient(-30deg, #3d0b0b 50%, #2b0808 50%);
  padding: 5px 10px;
  /* margin: 12px; */
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7d4d4;
  font-size: 1.2vw;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button1::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8585;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}




/* สไตล์สำหรับเนื้อหาของ popup */
.popup-content {
  background: radial-gradient(circle, rgba(255, 215, 0, 0.8), rgba(255, 119, 0, 0.6)); /* กราเดียนต์พื้นหลังที่มีแสง */
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.8), 0 0 50px rgba(255, 215, 0, 0.5); /* เพิ่มเงาและพุให้กับกล่อง */
  text-align: center;
  animation: scaleIn 0.5s ease-out, bounce 1s, pulse 2s infinite; /* แอนิเมชันการขยายตัว, กระโดด และพุ */
  position: relative;
  overflow: hidden;
  
}

/* สไตล์ของข้อความหัวเรื่องใน popup */
.popup-content h2 {
  /* margin: 0 0 10px;
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);  */
  font-size: 2.5rem; /* ปรับขนาดตัวอักษรให้ใหญ่ขึ้นหรือตามต้องการ */
  animation: blink 0.5s infinite; /* หรือความเร็วอื่นที่ต้องการ */
  background: linear-gradient(45deg, #FFD700, #FFCC00, #FFD700); /* สีทองสว่าง */
  -webkit-background-clip: text;
  color: transparent;
  filter: drop-shadow(0 0 10px #fffdef); /* เพิ่มเงาด้วย filter */
  text-align: center; /* จัดกึ่งกลางข้อความใน <h2> */
  
}

/* สไตล์ของข้อความใน popup */
.popup-content p {
  margin: 0 0 20px;
  color: #ffffff; /* สีตัวอักษร */
  font-size: 1.8rem;
}

/* สไตล์ของปุ่มใน popup */
.popup-content button {
  padding: 15px 30px;
  background: #ffffff;
  border: none;
  color: #f77f00;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background 0.3s, color 0.3s;
  font-weight: bold;
}

/* เอฟเฟกต์การ hover บนปุ่ม */
.popup-content button:hover {
  background: #f77f00;
  color: #ffffff;
}


@media (min-width: 1px) and (max-width: 426px) {




  .bodyDetailsports{
    container-type: inline-size;
    margin: auto;
    /* margin-top: -15%; */
  
    height: 26vw; /* ปรับขนาด height ตามความต้องการ */
    width: 90%;
    background-color: rgb(46, 43, 43);
    display: flex; /* ใช้ Flexbox เพื่อจัดแนวเนื้อหาภายใน */
    justify-content: center; /* จัดกลางในแนวนอน */
    align-items: center; /* จัดกลางในแนวตั้ง */
    border-radius: 20px;
    margin-top: 2%;
  }


  
  .bodyDetailsportsbox1 {
    margin: 2%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  .bodyDetailsportsbox1 img{
   
  margin-top: 6vw;
  }


  
.cardgameDetail {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* สร้าง 2 คอลัมน์ */
  grid-template-rows: repeat(3, 1fr); 
  margin: 2%;

  gap: 3vw; /* ระยะห่างระหว่างภาพ */
  width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
  /* height: 80%;  */
  box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
  overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
}

}


@media (min-width: 426px) and (max-width: 768px) {




  .cardgameDetail {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* สร้าง 2 คอลัมน์ */
    grid-template-rows: repeat(3, 1fr); 
    margin: 2%;
  
    gap: 3vw; /* ระยะห่างระหว่างภาพ */
    width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
    /* height: 80%;  */
    box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
    overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
  }


  .cardTestgame {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* สร้าง 2 คอลัมน์ */
    grid-template-rows: repeat(2, 1fr); 
    margin: 2%;
  
    gap: 3vw; /* ระยะห่างระหว่างภาพ */
    width: 100%; /* ใช้ความกว้างทั้งหมดของ .do-container-query-6game */
    height: auto; 
    box-sizing: border-box; /* ให้รวม padding และ border ในขนาด */
    overflow: hidden; /* ป้องกันการล้นของเนื้อหาภายใน */
  }

}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}


@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-30px); /* เคลื่อนที่ขึ้น 30px */
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-15px); /* เคลื่อนที่ขึ้น 15px */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(255, 215, 0, 0); /* ไม่มีเงา */
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.8); /* เพิ่มเงา */
    box-shadow: 0 0 0 rgba(255, 215, 0, 0); /* ไม่มีเงา */
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(255, 215, 0, 0); /* ไม่มีเงา */
  }
}

@keyframes shakeRight {
  0% {
    transform: translateX(0); /* เริ่มจากตำแหน่งเริ่มต้น */
  }
  25% {
    transform: translateX(5px); /* เคลื่อนที่ไปทางขวา */
  }
  50% {
    transform: translateX(0); /* กลับสู่ตำแหน่งเริ่มต้น */
  }
  75% {
    transform: translateX(5px); /* เคลื่อนที่ไปทางขวาอีกครั้ง */
  }
  100% {
    transform: translateX(0); /* กลับสู่ตำแหน่งเริ่มต้น */
  }
}



@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}